import { showNotification } from "@mantine/notifications";
import { AxiosError, AxiosHeaders, AxiosResponse, AxiosStatic } from "axios";
import { setupSharedAxios } from "sublinx-autopilot-shared";
import keycloak from "./keycloak";

const BASE_URL = import.meta.env.VITE_BASE_API_URL;
export function setupAxios(axios: AxiosStatic) {
  axios.defaults.baseURL = BASE_URL;
  axios.interceptors.request.use((config) => {
    config.paramsSerializer = { indexes: null };
    if (keycloak && keycloak.token) {
      (config.headers as AxiosHeaders).set(
        "Authorization",
        `Bearer ${keycloak.token}`,
      );
      (config.headers as AxiosHeaders).set("accept", "*/*");
    }
    return config;
  });

  axios.interceptors.response.use(
    async (response: AxiosResponse) => response,
    (
      error: AxiosError & {
        response: {
          data: {
            message: string;
          };
        };
      },
    ) => {
      if (error.code !== "ERR_NETWORK") {
        showNotification({
          id: "axiosError",
          color: "red",
          title: "Error",
          autoClose: 10000,
          message:
            error.response?.data?.message ||
            error.message ||
            "There was an error processing your request",
        });
      } else if (import.meta.env.DEV) {
        showNotification({
          id: "axiosError",
          color: "red",
          title: "Error",
          autoClose: 5000,
          message:
            error.message || "There was an error processing your request",
        });
      }
      //   if ((error.code === "ERR_BAD_REQUEST" || error.code === "ERR_NETWORK") && !error.response.data) {
      //     console.error("An error occurred. Please try again.");
      //   } else {
      //     toast.error(error.response.data || error?.response?.data.message || "An error occurred. Please try again.");
      //   }
      return Promise.reject(error);
    },
  );
  setupSharedAxios(axios);
}
