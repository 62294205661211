import {
  Center,
  Divider,
  Grid,
  Group,
  Loader,
  Paper,
  Stack,
  Text,
  Title,
  useMantineColorScheme,
} from "@mantine/core";
import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import { getIcon } from "../../assets/images/weatherIcons";
import { WeatherService } from "../../services/WeatherService";
import { Weather } from "../../utils/types";
import WeatherIcon from "../widgets/WeatherIcon";

export const WeatherWidget = () => {
  const { colorScheme } = useMantineColorScheme();
  const { data: weather, isLoading } = useQuery<Weather>({
    queryKey: ["weather", new Date().toLocaleDateString()],
    queryFn: WeatherService.getWeather,
    staleTime: 1000 * 60 * 60 * 24,
  });

  if (isLoading) {
    return (
      <Center p="xl">
        <Loader size="sm" />
      </Center>
    );
  }

  if (!weather || !weather.data) {
    return (
      <Center p="xl">
        <Text size="sm" c="dimmed">
          Unable to load weather data
        </Text>
      </Center>
    );
  }

  return (
    <>
      <Grid mb={0}>
        <Grid.Col span={8}>
          <Text size="lg" fw="bold">
            {weather.city_name} - Today
          </Text>
          <Divider my={5} />
          <Title order={2}>{weather.data[0].temp} &#8457;</Title>
          <Text size="sm" c="dimmed">
            {weather.data[0].high_temp} / {weather.data[0].low_temp} &#8457;
          </Text>
        </Grid.Col>
        <Grid.Col span={4}>
          <Center>
            <WeatherIcon
              size={80}
              path={getIcon(weather.data[0].weather.code)}
              title={weather.data[0].weather.description}
            />
          </Center>

          <Text size="sm" ta="center" c="dimmed">
            {weather.data[0].weather.description}
          </Text>
        </Grid.Col>
      </Grid>
      <Paper p="xs" mt="sm">
        <Group grow wrap="nowrap" justify="space-between" gap={0}>
          {weather?.data?.map((day, dayIndex) => (
            <Stack
              key={day.datetime}
              align="center"
              gap={5}
              style={(theme) => ({
                ...(dayIndex !== weather.data!.length - 1
                  ? {
                      borderRight: `1px solid ${
                        colorScheme === "light"
                          ? theme.colors.gray[4]
                          : theme.colors.gray[7]
                      }`,
                    }
                  : {}),
              })}
            >
              <Text size="xs" fw="bold" ta="center">
                {dayjs(day.datetime).format("ddd, MMM D")}
              </Text>
              <WeatherIcon
                size={30}
                path={getIcon(day.weather.code)}
                title={day.weather.description}
              />
              <Text size="xs" c="dimmed" ta="center">
                {day.weather.description}
                <br />
                {day.high_temp} / {day.low_temp} &#8457;
              </Text>
            </Stack>
          ))}
        </Group>
      </Paper>
    </>
  );
};
