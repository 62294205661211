export const phoneRegExp =
  /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;
export const validateEmail = (email: string) =>
  String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
export const formatCurrency = (value: number) =>
  new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(value);

export const formatPercent = (value?: number) => {
  if (value === null || value === undefined) {
    return null;
  }
  return Number(value / 100).toLocaleString(undefined, {
    style: 'percent',
    minimumFractionDigits: 2,
  });
};

export const formatPhoneNumber = (phoneNumber?: string) => {
  if (!phoneNumber || (phoneNumber && phoneNumber.length === 0)) {
    return phoneNumber;
  }
  const cleaned = `${phoneNumber}`.replace(/\D/g, '');
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`;
  }
}
export const capitalizeFirstLetter = (string?: string) => {
  if (!string || (string && string.length === 0)) {
    return string;
  }
  return string.charAt(0).toUpperCase() + string.toLowerCase().slice(1);
};

export const formatEnum = (value?: string) => {
  if (!value || (value && value.length === 0)) {
    return value;
  }
  return value
    .replace(/_/g, ' ')
    .toLowerCase()
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

export const stripNonNumericCharacters = (string: string) => {
  if (!string || (string && string.length === 0)) {
    return string;
  }
  return string.replace(/\D/g, '');
};

export const DateFormattingUtils = {
  standardDateFormatWithoutYear: 'M/D',
  standardDateFormatWithYear: 'M/D/YY',
  verboseDateTimeFormat: 'ddd MMM D, YYYY,  HH:mm a',
  verboseDateFormat: 'ddd MMM D',
  verboseDateFormatWithYear: 'ddd MMM D, YYYY',
  formatDateForPayload: (date: Date) => date.toISOString().split('T')[0],
  formatDateTimeForPayload: (date: Date) => date.toISOString(),
};

export const reorderArray = (list: any[], startIndex: number, endIndex: number): any[] => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const ellipsis = (string?: string, length?: number) => {
  if (!string || (string && string.length === 0)) {
    return string;
  }
  return string.length > length ? `${string.substring(0, length)}...` : string;
};

export const fetchAvatarInititals = (name?: string) => {
  if (!name || (name && name.length === 0)) {
    return '';
  }
  const initials = name.split(' ').map((n) => n[0]).join('');
  return initials;
}

export const formatStringListToNumberList = (list: string[] | undefined | null) => {
  if (!list || (list && list.length === 0)) {
    return [];
  }
  return list.map((item) => Number(item));
}

export const formatNumberListToStringList = (list: number[] | undefined | null) => {
  if (!list || (list && list.length === 0)) {
    return [];
  }
  return list.map((item) => item.toString());
}