import {
  Button,
  Container,
  Grid,
  Group,
  Image,
  List,
  Text,
  ThemeIcon,
  Title,
} from "@mantine/core";
import { useKeycloak } from "@react-keycloak/web";
import { IconCheck } from "@tabler/icons-react";
import { useQueryClient } from "@tanstack/react-query";
import { FC } from "react";
import { useLocation } from "react-router-dom";

import image from "../../../assets/images/landing/image.svg";

const BASE_URL = import.meta.env.VITE_BASE_URL;

interface stateType {
  from: { pathname: string };
}
export function HeroBullets() {
  const { keycloak } = useKeycloak();
  const location = useLocation();
  const queryClient = useQueryClient();
  const { from } = location.state
    ? (location.state as stateType)
    : { from: { pathname: "/" } };
  return (
    <Container size="lg">
      <Grid gutter={50} align="center" pt={50}>
        <Grid.Col span={{ base: 12, md: 6 }}>
          <Title size="h1">
            A{" "}
            <Text span c="blue" inherit>
              better
            </Text>{" "}
            Scheduling and Time Tracking System
          </Title>
          <Text c="dimmed" mt="md">
            Finally a scheduling system that actually works – Sublinx Autopilot
            uses state of the art technology to provide a better experience for
            you and your team.
          </Text>

          <List
            mt={30}
            spacing="sm"
            size="sm"
            icon={
              <ThemeIcon size={20} radius="xl">
                <IconCheck size={12} />
              </ThemeIcon>
            }
          >
            <List.Item>
              <b>GPS Time Tracking</b> – track time based on your employees gps
              location
            </List.Item>
            <List.Item>
              <b>Auto Scheduling</b> – your workorders are scheduled when the
              build sends over the purchase order
            </List.Item>
            <List.Item>
              <b>Informative Reporting</b> – get a glimpse of everything going
              on in seconds
            </List.Item>
          </List>

          <Group mt={30} wrap="nowrap">
            <Button
              id="cta-login-button"
              size="md"
              onClick={() => {
                queryClient.clear();
                keycloak.login({
                  redirectUri: `${BASE_URL}${from?.pathname}` || "/",
                });
              }}
            >
              Login to Autopilot
            </Button>
            <Button variant="default" size="md">
              Schedule Demo
            </Button>
          </Group>
        </Grid.Col>

        <Grid.Col span={{ base: 12, md: 6 }}>
          <Image src={image} />
        </Grid.Col>
      </Grid>
    </Container>
  );
}

export const Login: FC = () => (
  <>
    <HeroBullets />
  </>
);
