import { useMantineColorScheme } from '@mantine/core';
import { FC } from 'react';

interface Props {
  path: string;
  title?: string;
  viewBox?: string;
  size: number;
}

const WeatherIcon: FC<Props> = ({ title, path, size = 40, viewBox = '0 -5 35 40' }) => {
  const { colorScheme } = useMantineColorScheme();
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox={viewBox}
    >
      <title>{title}</title>
      <path d={path} style={{ fill: colorScheme === 'dark' ? '#fff' : '#000' }} />
    </svg>
  );
};

export default WeatherIcon;
