import axios from 'axios';
import { Weather } from '../utils/types';

export const WeatherService = {
  async getWeather(): Promise<Weather> {
    try {
      const location: GeolocationPosition = await new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject, {
          timeout: 5000,
          maximumAge: 0,
        });
      });

      const lat = location.coords.latitude;
      const lon = location.coords.longitude;
      const response = await axios.get(
        `https://api.weatherbit.io/v2.0/forecast/daily?key=6e218b62b3fb4c438a39abdc381dd404&units=I&days=3&lat=${lat}&lon=${lon}&appid=${import.meta.env.VITE_WEATHER_BIT_API_KEY
        }`
      );
      return response.data;
    } catch (error) {
      // Fall back to default location if geolocation fails
      const response = await axios.get(
        `https://api.weatherbit.io/v2.0/forecast/daily?key=6e218b62b3fb4c438a39abdc381dd404&units=I&days=3&lat=39.742043&lon=-104.991531&appid=${import.meta.env.VITE_WEATHER_BIT_API_KEY
        }`
      );
      return {
        ...response.data,
        using_default_location: true
      };
    }
  },
};
