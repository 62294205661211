import axios from "axios";
import { GlobalSearchDto } from "sublinx-autopilot-shared";
import LocalStorageService from "./LocalStorageService";

const RECENT_VISITS_KEY = "spotlight-recent-visits";
const MAX_RECENT_VISITS = 5;

interface Visit {
  id: number;
  name: string;
  type: "JOB";
  startTime: Date;
}

const SpotlightService = {
  fetchSpotlightActions: async (
    searchText: string,
  ): Promise<GlobalSearchDto> => {
    const response = await axios.get("/common/global/search", {
      params: {
        searchText,
      },
    });
    return response.data;
  },

  fetchRecentVisits: async (): Promise<Visit[]> => {
    return LocalStorageService.getList<Visit>(
      RECENT_VISITS_KEY,
      MAX_RECENT_VISITS,
    );
  },

  addRecentVisit: async (visit: Visit): Promise<void> => {
    await LocalStorageService.addToList(
      RECENT_VISITS_KEY,
      visit,
      MAX_RECENT_VISITS,
    );
  },
};

export default SpotlightService;
