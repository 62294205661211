type StorageItem<T> = {
  value: T;
  timestamp: number;
};

const LocalStorageService = {
  get<T>(key: string): T | null {
    try {
      const item = localStorage.getItem(key);
      if (!item) {
        return null;
      }

      const parsedItem: StorageItem<T> = JSON.parse(item);
      return parsedItem.value;
    } catch (error) {
      console.error(`Error getting item from localStorage: ${error}`);
      return null;
    }
  },

  set<T>(key: string, value: T): void {
    try {
      const item: StorageItem<T> = {
        value,
        timestamp: Date.now(),
      };
      localStorage.setItem(key, JSON.stringify(item));
    } catch (error) {
      console.error(`Error setting item in localStorage: ${error}`);
    }
  },

  remove(key: string): void {
    try {
      localStorage.removeItem(key);
    } catch (error) {
      console.error(`Error removing item from localStorage: ${error}`);
    }
  },

  getList<T>(key: string, maxItems?: number): T[] {
    try {
      const item = localStorage.getItem(key);
      if (!item) {
        return [];
      }

      const parsedItem: StorageItem<T[]> = JSON.parse(item);
      const list = parsedItem.value;
      return maxItems ? list.slice(0, maxItems) : list;
    } catch (error) {
      console.error(`Error getting list from localStorage: ${error}`);
      return [];
    }
  },

  addToList<T extends { id: number | string }>(
    key: string,
    item: T,
    maxItems?: number,
  ): void {
    try {
      const currentItem = localStorage.getItem(key);
      const parsedItem: StorageItem<T[]> = currentItem
        ? JSON.parse(currentItem)
        : { value: [], timestamp: Date.now() };

      // Find existing item index
      const existingIndex = parsedItem.value.findIndex((i) => i.id === item.id);

      let newList: T[];
      if (existingIndex !== -1) {
        // Update existing item and move to front
        parsedItem.value.splice(existingIndex, 1);
        newList = [item, ...parsedItem.value];
      } else {
        // Add to front of array if it doesn't exist
        newList = [item, ...parsedItem.value];
      }

      // Keep only up to maxItems
      const finalList = maxItems ? newList.slice(0, maxItems) : newList;

      LocalStorageService.set(key, finalList);
    } catch (error) {
      console.error(`Error adding item to list in localStorage: ${error}`);
    }
  },
};

export default LocalStorageService;
